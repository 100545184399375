/* You can add global styles to this file, and also import other style files */
.dt-buttons {
    margin-left:20px;
    margin-top:0px;
    margin-bottom:10px;
}

div.dataTables_wrapper div.dataTables_info {
    padding-left: 30px !important;
}

.ui.button {
    border-radius: 990px;
    color: white;
  }

  .ui.white.button {
    background-color: transparent;
    color: rgb(98, 96, 96);
  }

  .ui.white.button:hover {
    background-color: lightgrey;
    color: rgb(98, 96, 96);
  }

.ui.compact.basic.button {
    border-radius: 0 !important;
    margin-left:5px;
}

.ui.icon{
    opacity: 1;
}

.ui.compact.basic.button {
    border-radius: 0 !important;
    margin-left:5px;
}

.ui.labeled.icon.button {
    border-radius: 0 !important;
}

#upload-file {
    border-radius: 0 !important;
}

.copyToken {
    border-radius: 0 !important;
}

.ui.icon.edit 
.ui.icon.copy 
.ui.icon.print {
    color: #50bd5a !important;
}

.buttons-excel {
    background-color: lightgrey !important;
    border-radius: 0px !important;
    color: black !important;
}

.buttons-excel:hover {
    background-color: grey !important;
    border-radius: 0px !important;
    color: black !important;
}

.buttons-print {
    background-color: lightgrey !important;
    border-radius: 0px !important;
    color: black !important;
}

.buttons-print:hover {
    background-color: grey !important;
    border-radius: 0px !important;
    color: black !important;
}

.modal-backdrop {
    z-index: 1040 !important;
}
